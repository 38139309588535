import { NavLink } from "react-router-dom";
import { formatDistanceToNow } from 'date-fns';
import { parseISO } from 'date-fns';
import routes from "../../../routes";

export default function CardJobList(props) {
  const date = parseISO(props.created_at);
  const timeAgo = formatDistanceToNow(date, { addSuffix: true });

  return (
    <div className="card-job-list">
      <div className="top">
        <div className="left">
          <div className="images">
            {/* <img src={ props.images } alt="" /> */}
            {/* <img src={ props.images || "/svg/default-karir.svg"} alt="" /> */}
            <img src="/svg/default-karir.svg" alt="" />
          </div>
        </div>
        <div className="right">
          <div className="category">
            <p>{ props.category }</p>
            <p>{ timeAgo }</p>
          </div>
          <h2>{ props.position }</h2>
          <div className="place">
            <img src="/svg/location-job.svg" alt="" />
            <p>{ props.address }</p>
          </div>
        </div>
      </div>
      <hr />
      <div className="bottom">
        <div className="recap">
          <div className="item">
            <img src="/svg/job-clock.svg" alt="" />
            <p>{ props.type }</p>
          </div>
          <div className="item">
            <img src="/svg/job-dollar.svg" alt="" />
            <p>Rp { props.salary_lower_threshold } - Rp { props.salary_upper_threshold }</p>
          </div>
          { props.duration && <div className="item">
            <img src="/svg/job-case-2.svg" alt="" />
            <p>{ props.duration } bulan</p>
          </div> }
        </div>
        <p className="desc"
          style={ { lineHeight: "22px" } }
          dangerouslySetInnerHTML={ { __html: props.description } }>
        </p>
      </div>
      <NavLink to={ routes.careerDetail.replace(":id", props.id) }>
        <button className="button-custom">
          Selengkapnya
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.4497 9L3.19971 9"
              stroke="white"
              strokeWidth="0.65625"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.4497 5.25L14.8903 8.69064C15.0362 8.83647 15.1091 8.90939 15.1091 9C15.1091 9.09061 15.0362 9.16353 14.8903 9.30936L11.4497 12.75"
              stroke="white"
              strokeWidth="0.65625"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </NavLink>
    </div>
  );
}
