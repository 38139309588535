import Footer from "../../components/footer";
import TitleHeader from "../../components/title-header";
import Navbar from "../../components/navbar";
import { useLocation, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useCareerDetail } from "./queries/use-career-detail";

export default function CareerDetail() {
  let { state } = useLocation();
  const toast = useToast();
  const { id } = useParams();
  const careerDetail = useCareerDetail(id);

  return (
    <>
      <div className="wrapper-pages">
        <Navbar />
        <TitleHeader
          title={"Job Details"}
          bread={
            <div
              dangerouslySetInnerHTML={{
                __html: `Karir / ${
                  careerDetail?.data?.position ?? "-"
                } / <span>Detail</span>`,
              }}
            />
          }
        />

        {/* Careers */}
        {careerDetail.isLoading && (
          <div className="py-2 text-teal-900 font-bold text-center">
            Loading...
          </div>
        )}

        {careerDetail.isSuccess && (
          <div className="container">
            <div className="career-detail">
              <div className="top">
                <div className="left">
                  <img
                    className="icon-job"
                    src="/svg/career-detail.svg"
                    alt=""
                  />
                  <div className="text">
                    <h3>{careerDetail.data.career_division.name}</h3>
                    <h1>{careerDetail.data.position}</h1>
                    <div className="address">
                      <div className="item">
                        <img src="/svg/location.svg" alt="" />
                        <h3 className="ln-25">{careerDetail.data.address}</h3>
                      </div>
                      <div className="item">
                        <img src="/svg/clock.svg" alt="" />
                        <h3 className="ln-25">{careerDetail.data.type}</h3>
                      </div>
                      <div className="item">
                        <img src="/svg/dollar.svg" alt="" />
                        <h3 className="ln-25">
                          Rp {careerDetail.data.salary_lower_threshold} - Rp{" "}
                          {careerDetail.data.salary_upper_threshold}
                        </h3>
                      </div>
                      <div className="item">
                        <img src="/svg/briefcase.svg" alt="" />
                        <h3 className="ln-25">
                          {careerDetail.data.duration} bulan
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="right">
                  <button
                    type="button"
                    // onClick={() => {
                    //   window.open(careerDetail.data.link, "_blank");
                    // }}
                  >
                    Lamar Sekarang
                    <img src="/svg/button-arrow-right.svg" alt="" />
                  </button>
                </div>
              </div>

              <hr className="my-3" />

              <div className="job-desc-wrapper">
                <div className="job-desc">
                  <h2>Deskripsi Pekerjaan</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: careerDetail.data.description,
                    }}
                  ></p>
                </div>

                <section>
                  <h2>Kualifikasi</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: careerDetail.data.qualification,
                    }}
                  ></p>
                </section>

                <section>
                  <h2>Tugas dan Tanggung Jawab</h2>
                  <p
                    dangerouslySetInnerHTML={{ __html: careerDetail.data.duty }}
                  ></p>
                </section>

                <section>
                  <h2>Benefits</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: careerDetail.data.benefit,
                    }}
                  ></p>
                </section>
              </div>
            </div>
          </div>
        )}

        <Footer />
      </div>
    </>
  );
}
