import { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Accordion from "./component/accordion.jsx";
import Timeline from "./component/order-tracking.jsx";
import Orderdetail from "./component/order-detail-tracking.jsx"
import { useParams } from 'react-router-dom';
import { GetResi } from "./queries/use-resi-tracking";
export default function CekDetail() {
    const [isLoading, setIsLoading] = useState(true);
    const [dataAPI, setDataAPI] = useState([]);
    const { id } = useParams()

    // const products = [
    //     { name: "MamaBear Soya Mix", quantity: 2, price: 75000, imageUrl: "images/product-1.png" },
    //     { name: "MamaBear Soya Mix", quantity: 2, price: 75000, imageUrl: "images/product-1.png" },
    //     // tambahkan detail produk lainnya jika perlu
    // ];

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const getDetailFromAPI = async () => {
        try {
            const getAPI = await GetResi(id)
            setDataAPI(getAPI)
            // console.log(getAPI);
            setIsLoading(false);
        } catch (e) {
            console.error(e)
            setIsLoading(false);
        }
    }
    useEffect(() => {
        getDetailFromAPI()
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
        getDetailFromAPI();
    }, [id]);

    return (
        <div className="wrapper-pages">
            <Navbar />
            <div className="reseller">
                <div className="content">
                    <div className="cek-resi">
                        <h1>
                            <span>Lacak</span> Pengiriman
                        </h1>
                        {windowWidth > 600 && <p>Anda bisa memantau terus proses pengiriman anda</p>}
                        <div>
                            {isLoading ? (
                                <div>Loading...</div>
                            ) : (
                                <Accordion title="Produk">
                                    {/* {products.map((product, index) => (
                                    <div key={index} className="product-card">
                                        <div className="product-image">
                                            <img src={product.imageUrl} alt={product.name} />
                                        </div>
                                        <div className="product-details">
                                            <p className="nama-produk">{product.name}</p>
                                            <div className="iner-text">
                                                <span className="harga">Rp. {product.price}</span>
                                                <span className="quantity">x {product.quantity} Barang</span>
                                            </div>
                                        </div>
                                    </div>
                                ))} */}
                                    {dataAPI?.order?.order_products?.map((product, index) => (
                                        <div key={index} className="product-card position-relative">
                                            <div className="product-image">
                                                <img src={product.product_json.image_url_text} alt={product.product_json.name} />
                                            </div>
                                            <div className="product-details">
                                                <p className="nama-produk">{product.product_json.name}</p>
                                                <div className="iner-text">
                                                    <span className="harga">{product.product_json.price_text}</span>
                                                    <span className="quantity">x {product.qty} Barang</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Accordion>
                            )}
                        </div>
                        <div className="detai-alamat">
                            {isLoading ? (
                                <div>Loading...</div>
                            ) : (
                                <Orderdetail fullAdresOrigin={dataAPI.order?.origin_address_json?.full_address} destinationAdres={dataAPI.order.destination_address_json.full_address} />
                            )}
                        </div>

                        <div className="detai-pengiriman">
                            <div className="left">
                                <h3>Kurir</h3>
                                <p>
                                    <span className="background">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.526693 0.859375C0.526693 0.629256 0.713241 0.442708 0.943359 0.442708H6.73438C6.96449 0.442708 7.15104 0.629256 7.15104 0.859375V3.60144H9.80581C10.9564 3.60144 11.8891 4.53418 11.8891 5.68478V8.44934C11.8891 9.09264 11.4032 9.62244 10.7784 9.69167C10.7117 9.92234 10.5875 10.1401 10.4058 10.3218C9.8319 10.8957 8.90141 10.8957 8.32751 10.3218C8.14782 10.1421 8.02436 9.92716 7.95719 9.69934H6.73438H4.45865C4.39147 9.92716 4.26802 10.1421 4.08833 10.3218C3.51443 10.8957 2.58394 10.8957 2.01003 10.3218C1.82834 10.1401 1.70413 9.92234 1.63748 9.69167C1.01264 9.62244 0.526693 9.09264 0.526693 8.44934V6.01439H0.943359H3.57564C3.80576 6.01439 3.99231 5.82784 3.99231 5.59772C3.99231 5.3676 3.80576 5.18106 3.57564 5.18106H0.943359H0.526693V3.90794H0.943359H2.52273C2.75285 3.90794 2.93939 3.7214 2.93939 3.49128C2.93939 3.26116 2.75285 3.07461 2.52273 3.07461H0.943359H0.526693V0.859375ZM7.15104 8.866H7.95719C8.02436 8.63818 8.14782 8.42321 8.32751 8.24352C8.90141 7.66962 9.8319 7.66962 10.4058 8.24352C10.58 8.4177 10.7013 8.62505 10.7698 8.84511C10.9359 8.7903 11.0558 8.63382 11.0558 8.44934V5.68478C11.0558 4.99442 10.4962 4.43478 9.80581 4.43478H7.15104V8.866ZM3.49908 8.83278C3.25061 8.58431 2.84776 8.58431 2.59929 8.83278C2.47497 8.95709 2.41294 9.1192 2.41294 9.28267C2.41294 9.44615 2.47497 9.60825 2.59929 9.73256C2.84776 9.98103 3.25061 9.98103 3.49908 9.73256C3.62339 9.60825 3.68543 9.44615 3.68543 9.28267C3.68543 9.1192 3.62339 8.95709 3.49908 8.83278ZM9.81655 8.83278C9.56808 8.58431 9.16523 8.58431 8.91676 8.83278C8.79245 8.95709 8.73041 9.1192 8.73041 9.28267C8.73041 9.44615 8.79245 9.60825 8.91676 9.73256C9.16523 9.98103 9.56808 9.98103 9.81655 9.73256C9.94087 9.60825 10.0029 9.44615 10.0029 9.28267C10.0029 9.1192 9.94087 8.95709 9.81655 8.83278Z" fill="white" />
                                        </svg>
                                        Reguler
                                    </span>
                                    <span>
                                        {/* J&T Express */}
                                        {dataAPI?.order?.courier?.name} Express
                                    </span>
                                </p>
                                <p>Nomor Resi</p>
                                <p>Status Pengiriman</p>
                            </div>
                            <div className="right">
                                <h3>Estimasi Diterima</h3>
                                {isLoading ? (
                                    <div>Loading...</div>
                                ) : (
                                    <>
                                        <p>{dataAPI.order.estimated_on_user_at}</p>
                                        <p className="bold">#{dataAPI.order.waybill_id}</p>
                                        <p className="background-2">{dataAPI.order.status}</p>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="deteil-timeline">
                            {isLoading ? (
                                <div>Loading...</div>
                            ) : (
                                <Timeline trackingData={dataAPI.order.tracking} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
