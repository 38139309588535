import { useEffect, useState } from "react";
import AccordionCustom from "../components/accordion";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import TitleHeader from "../components/title-header";
import { useFaqs } from "./homepage/queries/use-faq";

export default function Faq() {
  // const faqs = useFaqs();
  const [faqs, setFaqs] = useState({
    isLoading: false,
    isFetching: false,
    isSuccess: true,
    data: [
      {
        name: "Produk",
        faqs: [
          { 
            subtitle: "Apa itu Mamabear ?", 
            text: "Mamabear adalah penyedia produk- produk pelancar ASI dengan bahan- bahan alami. Founder Agnes Susanti Widjaja adalah ibu menyusui sekaligus Bachelor degree in Science in Food Technology & Nutrition lulusan Royal Melbourne Institute of Technology. Melalui riset, pengalaman dan dedikasinya, Mamabear hadir sebagai solusi ASI terbaik bagi Anda. <a href='/our-story'>Baca selengkapnya.</a>" 
          },
          { 
            subtitle: "Apa saja produk- produk Mamabear?", 
            text: "Mamabear menyediakan produk- produk pelancar ASI yaitu : <a href='' style='color: #de8d8d;'>Teh Pelancar ASI</a>,  <a href='' style='color: #de8d8d;'>Minuman Bubuk (Zoya Mix dan Almond Mix)</a>, dan <a href='' style='color: #de8d8d;'>Almond Oat Cookies </a>. Kami juga menawarkan Kantong ASI dan produk menarik lainnya.Zoya Mix dan <a href='' style='color: #de8d8d;'>Almond Oat Cookies </a> juga bisa dikonsumsi oleh semua usia, termasuk anak- anak sebagai cemilan atau sarapan sehat." 
          },
          {
            subtitle:'Apa keunggulan produk Mamabear?',
            text: 'Kami hanya menggunakan bahan – bahan alami berkualitas yang diproduksi dengan teknologi dan proses terbaik. Seluruh produk kami aman dan efektif, serta praktis untuk ibu menyusui.Seluruh produk Mamabear juga telah lulus uji BPOM dan tersertifikasi Halal.<br /><br /> Zoya Mix dan Almond Oat Cookies juga bisa dikonsumsi oleh semua usia, termasuk anak- anak sebagai cemilan atau sarapan sehat.',
          },
          {
            subtitle:'Apa bahan- bahan produk Mamabear?',
            text: 'Untuk info bahan- bahan kami, <a href="/ingredients" style="color: #de8d8d;">silahkan klik disini.</a>',
          },
          {
            subtitle:'Produk pelancar ASI yang paling cocok untuk saya?',
            text: 'Setiap produk Mamabear dibuat untuk ibu menyusui. Namun, setiap hasil yang dirasakan akan berbeda- beda pada setiap individual. Sesuai pengalaman kami, Teh Pelancar ASI dan Zoya Milk efektif untuk meningkatkan produksi ASI. Sedangkan Almond Mix dan Almond Oat Cookies bisa memperkaya kualitas ASI.<br /><br /> Beberapa ibu menyusui juga sangat cocok dengan produk teh, sedangkan yang lainnya lebih merasakan hasil dari minuman bubuk. Untuk rekomendasi terbaik, kami sangat menyarankan untuk mencoba seluruh produk Mamabear. Jika dikombinasikan secara bersamaan, hasilnya juga akan lebih maksimal.',
          },
          {
            subtitle:'Apa perbedaan antara Zoya Mix dan Almond Mix?',
            text: 'Zoya Mix dan Almond Mix adalah produk minuman bubuk dari bahan – bahan tumbuhan yang didesain untuk meningkatkan produksi ASI. Keduanya juga punya rasa lezat dan praktis digunakan. <br /><br /> Perbedaan antara kedua produk ini lebih ke bahan – bahan yang digunakan dan rasa yang tersedia. Silahkan pilih sesuai selera Anda!',
          },
          {
            subtitle:'Apakah ada peringatan kesehatan sebelum pemakaian produk Mamabear?',
            text:'Seluruh produk kami terbuat dari bahan- bahan alami dan pilihan yang aman untuk ibu menyusui.<br /><br /> Zoya Mix dan Almond Oat Cookies juga cocok untuk segala usia, termasuk untuk ibu hamil. Konsumsi Almond Oat Cookies sangat baik untuk persiapan menyusui bagi ibu hamil yaitu untuk stimulasi kelenjar payudara untuk produksi ASI.<br /><br /> Konsumsi seluruh produk Mamabear sesuai petunjuk di kemasan. Anda juga boleh berkonsultasi dengan dokter Anda sebelum mengkonsumsi produk kami.',
          },
          {
            subtitle:'Apakah ada efek samping dari produk Mamabear?',
            text:'Jika dikonsumsi secara tepat dan sesuai dengan porsi yang direkomendasi di kemasan, produk Mamabear tidak memiliki efek samping atau ketergantungan. Anda boleh berkonsultasi dengan dokter Anda sebelum mengkonsumsi produk kami dan baca laman bahan – bahan kami untuk informasi lebih lanjut. ',
          },
          {
            subtitle:'Apakah ibu hamil boleh mengkonsumsi produk Mamabear?',
            text:'Teh Pelancar ASI Mamabear boleh dikonsumsi setelah melahirkan atau paska bersalin. Ibu hamil juga boleh mengkonsumsi teh setelah minggu ke-38 kehamilan.<br /><br /> Selain itu, Zoya Mix dan Almond Oat Cookies juga aman dan cocok untuk segala usia. ',
          },
          {
            subtitle:'Bagaimana cara konsumsi produk Mamabear agar cepat mendapatkan hasil?',
            text:`Ikuti anjuran pemakaian yang tersedia untuk masing- masing produk. Jika ingin mengkombinasikan beberapa produk Mamabear sekaligus, Anda boleh menggunakan secara bergantian, misalnya :<br /><br />
            <ul>
                <li>Sarapan dengan Zoya Mix</li>
                <li>Minum Teh Pelancar ASI di siang hari</li>
                <li>Cemilan sore dengan Almond Oat Cookies atau Zoya Mix/ Teh Pelancar ASI</li>
                <li>Minum Zoya Mix sebelum tidur</li>
                <li>Almond Oat Cookies bisa digunakan sebagai snack kapan saja disukai.</li>
            </ul>`,
          },
          {
            subtitle:'Apakah produk Mamabear bisa digunakan secara bersamaan?',
            text:'Ya, kombinasi produk- produk Mamabear dapat saling melengkapi dan mendukung produksi ASI yang semakin maksimal. Seluruh baha n- bahan kami bersifat alami dan tidak mengandung bahan kimia berbahaya. Dengan demikian, Anda tidak perlu khawatir akan overdosis dan bebas mengkonsumsi produk – produk Mamabear secara bersamaan sesuai selera.',
          },
          {
            subtitle:'Apakah produk Mamabear aman untuk yang alergi susu?',
            text:'Hampir seluruh produk kami tidak mengandung susu dan produk turunannya, KECUALI Almond Oat Cookies varian Chocolate Chip dan Cookies and Cream. Untuk Almond Oat Cookies rasa kurma tidak mengandung susu.',
          },
        ]
      },
      {
        name: "Order",
        faqs: [
          { 
            subtitle: "Bagaimana cara pesan produk Mamabear?", 
            text: "Buat akun dengan cara mendaftarkan email Anda. Log in dan tambahkan produk ke keranjang, lalu lengkapi pembayaran untuk menyelesaikan pesanan. Produk Mamabear juga tersedia di mitra resmi kami di Tokopedia, Shopee, JD.ID. Blibli dan Bukalapak." 
          },
          {
            subtitle:'Apa saja sistem pembayaran yang tersedia?',
            text:'Kami menerima berbagai bentuk pembayaran, antara lain transfer bank (BCA & Mandiri), kartu kredit, GoPay dan alfamart.',
          },
          {
            subtitle:'Berapa lama untuk proses pengiriman?',
            text:'Pesanan Anda akan diproses dalam 3-4 hari kerja. Lama pengiriman tergantung pada lokasi tujuan.',
          },
          {
            subtitle:'Apakah saya bisa mengganti pesanan atau alamat jika pembayaran sudah terkonfirmasi?',
            text:'Untuk mengubah pesanan, langsung <a href="" style="color: #de8d8d;">WhatsApp</a> untuk menghubungi tim kami. Untuk mengubah alamat, silahkan batalkan pesanan untuk membuat pesanan baru.',
          },
          {
            subtitle:'Apakah ada diskon belanja?',
            text:'Silahkan subscribe ke newsletter kami untuk mendapatkan info penawaran dan update menarik lainnya!',
          },
        ]
      },
      {
        name: "Lain-Lain",
        faqs:[
          {
            subtitle:'Apakah Mamabear punya program reseller?',
            text:'Silahkan hubungi kami via <a href="" style="color: #de8d8d;">Whatsapp</a>.',
          },
        ]
      }
    ],
    error: null,
  });
  // console.log("ini data" , faqs.data);

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (idx) => {
    setOpenIndex(openIndex === idx ? null : idx);
  };

  return (
    <div className="wrapper-pages">
      <Navbar />
      <TitleHeader
        title={"Frequently Asked Questions"}
        bread={
          <div
            dangerouslySetInnerHTML={{ __html: "Bantuan / <span>FAQ</span>" }}
          />
        }
      />

      {faqs.isLoading && (
        <div className="py-2 text-teal-900 font-bold">Loading...</div>
      )}

      {faqs.isFetching && (
        <div className="py-2 text-teal-900 font-bold">Fetching...</div>
      )}

      {faqs.error instanceof Error && <div>{faqs.error.message}</div>}

      <div className="container">
        <div className="faq-pages">
          {faqs.isSuccess &&
            faqs.data.map((faq, index) => (
              <div className="item" style={{ marginTop: "40px" }}>
                <h1>{faq.name}</h1>
                <div className="accordion" id="accordionExample">
                  {/* {faq.faqs.map((item) => (
                    <>
                      <AccordionCustom
                        desc={item.text}
                        title={item.subtitle}
                        target={"target2"}
                        show={true}
                        collapsed={true}
                      />
                    </>
                  ))} */}
                  {faq.faqs.map((item, idx) => (
                    <AccordionCustom
                      key={idx}
                      desc={item.text}
                      title={item.subtitle}
                      target={`target-${index}-${idx}`}
                      show={false}
                      collapsed={true}
                    />
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
