import Navbar from "../components/navbar";
import Footer from "../components/footer";
import TextSection from "../components/text-section";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination"
import "swiper/css/effect-coverflow";
import "react-tabs/style/react-tabs.css";

import BannerTC from "../../../assets/images/banner-term-condition.png";

export default function TermCondition() {
    const termData = {
        isFetching: false,
        isSuccess: true,
        data: [
            {
                title: 'Perkenalan',
                desc: 'Syarat dan Ketentuan Standar Situs Web ini yang ditulis pada halaman web ini akan mengatur penggunaan Anda atas situs web kami, Mamabear dapat diakses di https://mamabear.co.id. <br /></br>Ketentuan ini akan diterapkan sepenuhnya dan mempengaruhi penggunaan situs web ini oleh Anda. Dengan menggunakan Situs Web ini, Anda setuju untuk menerima semua syarat dan ketentuan yang tertulis di sini. Anda tidak boleh menggunakan Situs Web ini jika Anda tidak setuju dengan Syarat dan Ketentuan Standar Situs Web ini. <br/><br/>Anak atau orang di bawah 18 tahun tidak diperbolehkan menggunakan Situs Web ini.',
            },
            {
                title: 'Hak Kekayaan Intelektual',
                desc: 'Selain konten yang Anda miliki, berdasarkan Ketentuan ini, Mamabear dan / atau pemberi lisensinya memiliki semua hak kekayaan intelektual dan materi yang terkandung dalam Situs Web ini.<br/><br/>Anda diberikan lisensi terbatas hanya untuk tujuan melihat materi yang terkandung di Situs Web ini.',
            },
            {
                title: 'Batasan',
                desc: `
                    Anda secara khusus dibatasi dari semua hal berikut:<br/><br/>

                    <ul>
                        <li>menerbitkan materi situs web apa pun di media lain apa pun.</li>
                        <li>menjual, mensublisensikan dan / atau mengkomersialkan semua materi situs web.</li>
                        <li>pertunjukan publik dan / atau menunjukkan materi situs web apa pun.</li>
                        <li>menggunakan Situs Web ini dengan cara apa pun yang dapat atau dapat merusak Situs Web ini.</li>
                        <li>menggunakan Situs Web ini bertentangan dengan hukum dan peraturan yang berlaku, atau dengan cara apa pun dapat membahayakan Situs Web, atau orang atau entitas bisnis apa pun.</li>
                        <li>terlibat dalam penggalian data, pengambilan data, penggalian data atau aktivitas serupa lainnya sehubungan dengan Situs Web ini.</li>
                        <li>menggunakan Situs Web ini untuk terlibat dalam iklan atau pemasaran apa pun.</li>
                    </ul>

                    <br/><br/>
                    Area-area tertentu dari Situs Web ini dibatasi agar tidak dapat diakses oleh Anda dan Mamabear selanjutnya dapat membatasi akses Anda ke area mana pun dari Situs Web ini, kapan saja, dengan kebijaksanaan mutlak. ID pengguna dan kata sandi apa pun yang Anda miliki untuk Situs Web ini adalah rahasia dan Anda harus menjaga kerahasiaan juga.
                `,
            },
            {
                title: 'Konten Anda',
                desc: 'Dalam Syarat dan Ketentuan Standar Situs Web ini, “Konten Anda” berarti segala audio, teks video, gambar atau materi lain yang Anda pilih untuk ditampilkan di Situs Web ini. Dengan menampilkan Konten Anda, Anda memberi Mamabear lisensi sub-lisensi non-eksklusif di seluruh dunia untuk menggunakan, mereproduksi, mengadaptasi, menerbitkan, menerjemahkan, dan mendistribusikannya di media apa saja dan semua.<br/><br/>Konten Anda harus menjadi milik Anda dan tidak boleh melanggar hak pihak ketiga mana pun. Mamabear berhak untuk menghapus Konten Anda dari situs web ini kapan saja tanpa pemberitahuan.',
            },
            {
                title: 'Tidak Ada Jaminan',
                desc: 'Situs web ini disediakan “sebagaimana adanya,” dengan semua kesalahan, dan Mamabear tidak menyatakan pernyataan atau jaminan, apa pun yang terkait dengan Situs Web ini atau materi yang terkandung di Situs Web ini. Juga, tidak ada yang terkandung dalam Situs Web ini yang akan ditafsirkan sebagai menasihati Anda.',
            },
            {
                title: 'Batasan Tanggung Jawab',
                desc: 'Dalam keadaan apa pun, Mamabear, maupun para pejabat, direktur, dan karyawannya, tidak akan dimintai tanggung jawab atas apa pun yang timbul dari atau dengan cara apa pun yang terkait dengan penggunaan Anda terhadap Situs Web ini, walaupun kewajiban tersebut berdasarkan kontrak. Mamabear, termasuk pejabat, direktur, dan karyawannya tidak akan bertanggung jawab atas segala kewajiban tidak langsung, konsekuensial, atau khusus yang timbul dari atau dengan cara apa pun terkait dengan penggunaan Anda terhadap situs web ini.',
            },
            {
                title: 'Ganti Rugi',
                desc: 'Anda dengan ini memberi ganti rugi sepenuhnya kepada Mamabear dari dan terhadap setiap dan / atau semua kewajiban, biaya, tuntutan, penyebab tindakan, kerusakan, dan biaya yang timbul dengan cara apa pun terkait dengan pelanggaran Anda terhadap salah satu ketentuan dari Ketentuan ini.',
            },
            {
                title: 'Keterpisahan',
                desc: 'Jika ada ketentuan dalam Ketentuan ini yang ditemukan tidak sah menurut hukum yang berlaku, ketentuan tersebut akan dihapus tanpa mempengaruhi ketentuan lainnya di sini.',
            },
            {
                title: 'Variasi Ketentuan',
                desc: 'Mamabear diizinkan untuk merevisi Ketentuan ini kapan saja sesuai keinginan, dan dengan menggunakan Situs Web ini Anda diharapkan untuk meninjau Ketentuan ini secara berkala.',
            },
            {
                title: 'Tugas',
                desc: 'Mamabear diizinkan untuk menetapkan, mentransfer, dan mensubkontrakkan hak dan / atau kewajibannya berdasarkan Ketentuan ini tanpa pemberitahuan apa pun. Namun, Anda tidak diizinkan untuk menetapkan, mentransfer, atau mensubkontrakkan semua hak dan / atau kewajiban Anda berdasarkan Ketentuan ini.',
            },
            {
                title: 'Seluruh Perjanjian',
                desc: 'Ketentuan-ketentuan ini merupakan keseluruhan perjanjian antara Mamabear dan Anda sehubungan dengan penggunaan Anda atas Situs web ini, dan menggantikan semua perjanjian dan pemahaman sebelumnya.',
            },
            {
                title: 'Hukum & Yurisdiksi yang Berlaku',
                desc: 'Ketentuan ini akan diatur oleh dan ditafsirkan sesuai dengan hukum Indonesia, dan Anda tunduk pada yurisdiksi non-eksklusif dari pengadilan negara bagian dan federal yang berlokasi di id untuk penyelesaian perselisihan apa pun.',
            },
        ]
    };

    return (
        <div className="wrapper-pages">
            <Navbar />

            <div className="about-us">
                <div className="hero">
                    <img src={BannerTC} alt="" />
                    <div className="wrapper">
                        <div className="title-hero">
                            <h1>Terms & <span>Condition</span></h1>
                        </div>
                    </div>
                </div>

                <div className="isi-term">
                    <div className="wrapper">
                        <div className="container">
                            <div className="wrapp-text">
                                {termData.data.map((item, index) => (
                                    <TextSection
                                        key={index}
                                        title={`${index + 1}. ${item.title}`}
                                        desc={item.desc}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="afiliator">
                    <div className="content">
                        <div className="join-reseller join-afiliator">
                            <div className="container">
                                <div className="join-reseller-inner">
                                    <div className="right">
                                        <div className="text">
                                            <h4>Ayoo!!</h4>
                                            <h1 className="h1-xl">Bergabung Affiliate MamaBear</h1>
                                            <p className="p-md">
                                                Hubungi Whatsapp di bawah ini Untuk Mendaftar Menjadi
                                                Reseller MamaBear!
                                            </p>
                                        </div>

                                        <button
                                            onClick={() =>
                                                window.open("https://wa.me/628888695757", "_blank")
                                            }
                                        >
                                            Daftar Sekarang
                                            <svg
                                                width="25"
                                                height="24"
                                                viewBox="0 0 25 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M6.91438 16.5315C6.65562 16.8549 6.70806 17.3269 7.03151 17.5856C7.35495 17.8444 7.82692 17.7919 8.08568 17.4685L12.0857 12.4685C12.3048 12.1946 12.3048 11.8054 12.0857 11.5315L8.08568 6.53145C7.82692 6.208 7.35495 6.15556 7.03151 6.41432C6.70806 6.67308 6.65562 7.14505 6.91438 7.46849L10.5396 12L6.91438 16.5315ZM12.9144 16.5315C12.6556 16.8549 12.7081 17.3269 13.0315 17.5856C13.355 17.8444 13.8269 17.7919 14.0857 17.4685L18.0857 12.4685C18.3048 12.1946 18.3048 11.8054 18.0857 11.5315L14.0857 6.53145C13.8269 6.208 13.355 6.15556 13.0315 6.41432C12.7081 6.67308 12.6556 7.14505 12.9144 7.46849L16.5396 12L12.9144 16.5315Z"
                                                    fill="#DE8D8D"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="left in-afiliator">
                                        <img src="images/afiliator-4-2.jpg" alt="" />
                                        <img
                                            className="desktop"
                                            src="images/afiliator-4-2.jpg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}