import Navbar from "../components/navbar";
import Footer from "../components/footer";
import TextSection from "../components/text-section";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination"
import "swiper/css/effect-coverflow";
import "react-tabs/style/react-tabs.css";

import BannerIngre from "../../../assets/images/banner-ingredients.png";

export default function Referensi() {
    return (
        <div className="wrapper-pages">
            <Navbar />

            <div className="about-us">
                <div className="hero">
                    <img src={BannerIngre} alt="" />
                    <div className="wrapper">
                        <div className="title-hero">
                            <h1>Referensi  <span>Bahan - Bahan</span></h1>
                        </div>
                    </div>
                </div>

                <div className="referensi">
                    <div className="container">
                        <div className="wrapper">
                            <div className="">
                                <p className="title">Daftar Referensi</p>
                                <p className="sub-title">Berikut merupakan daftar referensi yang digunakan dalam pemilihan bahan</p>
                            </div>

                            <ol>
                                <li>
                                    Felipe Penagos Tabares, Juliana V. Bedoya Jaramillo, and Zulma Tatiana Ruiz-Cortés (2014). Pharmacological Overview of Galactogogues. The New England Journal of Medicine.
                                </li>
                                <li>
                                    Turkyilmaz C, Onal E, Hirfanoglu IM, et al. The effect of galactagogue herbal tea on breast milk production and short-term catch-up of birth weight in the first week of life. J Altern Complement Med 2011;17(2):139-42.
                                </li>
                                <li>
                                    Vitamins & Supplements : Fenugreek. Webmd.
                                </li>
                                <li>
                                    Gong J, Fang K, Dong H, Wang D, Hu M, Lu F. Effect of fenugreek on hyperglycemia and hyperlipidemia in diabetes and prediabetes: A meta-analysis. J Ethnopharmacol. 2016 Dec 24;194:260-268.
                                </li>
                                <li>
                                    Mills S, Bone K. Principles and Practice of Phytotherapy: Modern Herbal Medicine. Edinburgh, UK: Churchill Livingstone; 2000.
                                </li>
                                <li>
                                    Agrawala IP, Achar MV, Boradkar RV, Roy N. Galactagogue action of Cuminum cyminum and Nigella staiva . Indian Journal of Medical Research. PubMed.1968;56(6):841–844.
                                </li>
                                <li>
                                    Brodribb W. ABM Clinical Protocol #9: Use of galactogogues in initiating or augmenting maternal milk production, second revision 2018. Breastfeed Med. 2018;13:307-14.
                                </li>
                                <li>
                                    Ottolenghi, A., De Chiara, A., Arrigoni, S., Terracciano, L., and De Amici, M. [Diagnosis of food allergy caused by fruit and vegetables in children with atopic dermatitis]. Pediatr Med Chir 1995;17(6):525-530.
                                </li>
                                <li>
                                    Zohary D, Hopf M. Domestication of Plants in the Old World. Oxford University Press; 2001.
                                </li>
                                <li>
                                    THE EFFECTIVENESS OF FLAXSEED (Linum usitatissimum) AND BLACK SEEDS (Nigella sativa) COOKIES TO INCREASE BREASTMILK VOLUME IN SLEMAN DISTRICT GY Okinarum Department of Midwifery, Faculty of Health Science, University of Respati Yogyakarta
                                </li>
                                <li>
                                    AKTIVITAS FARMAKOLOGI EKSTRAK DAUN KATUK (Sauropus androgynus (L.) Merr) Tiara Salsabila Majid, Muchtaridi Muchtaridi Fakultas Farmasi Universitas Padjadjaran
                                </li>
                                <li>
                                    Sa’roni, T. Sadjimin, M. Sja’bani, Zulaela. Effectiveness of the Sauropus androgynus (L.) Merr leaf extracts in increasing mother’s breast milk production. Media Litbang Kes 2004, 14:20-24
                                </li>
                                <li>
                                    Bender A. E., Ismail K. S. Nutritive value and toxicity of Sauropus androgynous . The Proceedings of the Nutrition Society. 1973;32(2):79A–80A.
                                </li>
                                <li>
                                    Marasco, Lisa. (2008). Inside track. Increasing your milk supply with galactogogues.. Journal of human lactation : official journal of International Lactation Consultant Association. PubMed. 24. 455-6. 10.1177/08903344080240041501.
                                </li>
                                <li>
                                    Francois CA, Connor SL, Bolewicz LC, Connor WE. Supplementing lactating women with flaxseed oil does not increase DHA in their milk. Am J Clin Nutr 2003;77:226–33.
                                </li>
                                <li>
                                    Prasad K, Dhar A. Flaxseed and Diabetes. Curr Pharm Des. 2016;22(2):141-144. doi:10.2174/1381612822666151112151230
                                </li>
                                <li>
                                    Hillary Jacobson. Mother Food: A Breastfeeding Diet Guide with Lactogenic Foods and Herbs
                                </li>
                                <li>
                                    Antonakou A, Skenderi KP, Chiou A, Anastasiou CA, Bakoula C, Matalas AL. Breast milk fat concentration and fatty acid pattern during the first six months in exclusively breastfeeding Greek women [published correction appears in Eur J Nutr. 2016 Sep;55(6):2177]. Eur J Nutr. 2013;52(3):963-973. doi:10.1007/s00394-012-0403-8

                                </li>

                            </ol>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />
        </div>
    );
}