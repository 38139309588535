export default function AccordionCustom(props) {
  return (
    <div className="acc-item">
      <div
        // className={`title ${!props.collapsed ? "collapsed" : ""}`}
        className={`title collapsed`}
        data-toggle="collapse"
        data-target={`#${props.target}`}
        aria-expanded={"false"}
        aria-controls={props.target}
        
      >
        <h2>{props.title}</h2>
        <div className="line">
          <span className="line-horizontal"></span>
          <span className="line-vertikal"></span>
        </div>
      </div>

      <div
        className={`desc collapse ${props.show ? "show" : ""}`}
        id={props.target}
        aria-labelledby="headingOne"
        data-parent={`#${props.target}`}
      >
        <p dangerouslySetInnerHTML={{ __html: props.desc }}></p>
      </div>
    </div>
  );
}
